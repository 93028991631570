import { ValidationError } from 'yup';
import { useTranslation } from 'next-i18next';
import { isNullOrUndefined } from '@utils/utils';

export const validate = (schema, data, context?: any): ValidationError[] => {
  try {
    schema.validateSync(data, {
      abortEarly: false,
      recursive: true,
      strict: false,
      context,
    });
    return [];
  } catch (err) {
    return err.inner;
  }
};

interface ComponentPros {
  errors: ValidationError[];
  path: string;
  wrapperStyle?: any;
  overidedErrorMessage?: string;
}

export const findValidationMessage = (errors: ValidationError[], path: string, t: any) => {
  if (isNullOrUndefined(errors)) return null;

  const error = errors.find((e) => e.path === path);
  if (isNullOrUndefined(error)) return undefined;
  return t(`validation.${error.type}`);
};

const ValidationDiv: React.FC<ComponentPros> = ({ path, errors, overidedErrorMessage, wrapperStyle }) => {
  const { t } = useTranslation('common');

  const error = errors?.find((e) => e.path === path);
  if (error)
    return (
      <div className="p-invalid-feedback validationErrorMessage" style={wrapperStyle}>
        {!overidedErrorMessage && <>{t(`validation.${error.type}`)}</>}
        {overidedErrorMessage && <>{overidedErrorMessage}</>}
      </div>
    );
  return <div className="p-invalid-feedback "></div>;
};

export default ValidationDiv;
